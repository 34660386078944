/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.1.0-v202412092309-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface TableDefinitionInputV1 {
    /**
     * The action to take for a batch update. Valid options are as follows (UPDATE_EXISTING_INSERT_NEW_CLEANUP is the default): UPDATE_EXISTING_INSERT_NEW_CLEANUP: Update existing rows and insert new rows, but remove any rows that weren't updated or inserted UPDATE_EXISTING_INSERT_NEW: Update existing rows and insert new rows ONLY_UPDATE_EXISTING: Only update existing rows, but don’t insert new rows ONLY_INSERT_NEW: Only insert new rows, but don’t update existing rows 
     */
    "batchAction"?: BatchActionEnum;
    /**
     * Column definitions for the Table. A column definition contains all the properties that define each column in the table. An \"item id\" column is required and must reference a Seeq item (e.g. Condition ID). A \"datum id\" column is required because it is used to uniquely identify a row (e.g. Capsule Start property).
     */
    "columnDefinitions": Array<models.ColumnDefinitionInputV1>;
    /**
     * A unique identifier for the table definition within its datasource
     */
    "dataId"?: string;
    /**
     * Along with the Datasource ID, the Datasource Class uniquely identifies a datasource
     */
    "datasourceClass"?: string;
    /**
     * Along with the Datasource Class, the Datasource ID uniquely identifies a datasource
     */
    "datasourceId"?: string;
    /**
     * Clarifying information or other plain language description of this item. An input of just whitespaces is equivalent to a null input.
     */
    "description"?: string;
    /**
     * Human readable name. Required during creation. An input of just whitespaces is equivalent to a null input.
     */
    "name": string;
    /**
     * The ID of the workbook to which the table definition will be scoped. If not provided, the table definition will have a global scope
     */
    "scopedTo"?: string;
    /**
     * The ID of a Condition Monitor or Item Finder that will populate the rows of the table
     */
    "subscriptionId"?: string;
}

export enum BatchActionEnum {
    UPDATEEXISTINGINSERTNEWCLEANUP = 'UPDATE_EXISTING_INSERT_NEW_CLEANUP' as any,
    UPDATEEXISTINGINSERTNEW = 'UPDATE_EXISTING_INSERT_NEW' as any,
    ONLYUPDATEEXISTING = 'ONLY_UPDATE_EXISTING' as any,
    ONLYINSERTNEW = 'ONLY_INSERT_NEW' as any
}
